import React, { useState } from "react";
import "./Login.css"; // Ensure this path is correct
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { LoginFormProps } from "../interfaces";
import ResponsiveTypography from "../components/ResponsiveTypography";
import { useNavigate } from "react-router-dom";

export const Login: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await onSubmit(username, password);
      navigate("/home");
    } catch (error: any) {
      setError(error.message);
      setOpen(true);
    }
  };

  return (
    <div className="login-container">
      <Box
        className="login-form-container"
        component="form"
        onSubmit={handleSubmit}
      >
        <Box className="centered-content">
          <div
            style={{
              position: "relative",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <img
              src="./logo.svg"
              alt="Logo"
              style={{
                width: "94px",
                height: "94px",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                // Adjust these as necessary to position the ® inside the top right corner of the image
                fontSize: "12px", // Smaller font size for inside the image
                fontWeight: "bold",
                color: "#C72128", // Color of the ® to ensure visibility
                // Removing the transform property to keep the symbol inside
              }}
            >
              ®
            </span>
          </div>
          <ResponsiveTypography
            sx={{
              fontSize: { xs: "0.5rem", lg: "1rem" },
              fontFamily: "'Alice', serif", // Apply Alice font
            }}
          >
            K C SIVA REDDY
          </ResponsiveTypography>
          <ResponsiveTypography
            sx={{
              fontSize: { xs: "0.4rem", lg: "0.8rem" },
              fontFamily: "'Alice', serif", // Apply Alice font
            }}
          >
            Pure Ghee Sweets
          </ResponsiveTypography>
          <ResponsiveTypography
            sx={{
              fontSize: { xs: "0.3rem", lg: "0.6rem" },
              fontFamily: "'Alice', serif", // Apply Alice font
            }}
          >
            Estd 1978
          </ResponsiveTypography>
        </Box>
        <Typography
          variant="h5"
          gutterBottom
          className="login-typography"
          sx={{ marginY: "1.25rem" }}
        >
          LOGIN
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{
            style: {
              backgroundColor: "#f6f3cc",
            },
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            style: {
              backgroundColor: "#f6f3cc",
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          className="submit-button"
          sx={{ mt: 2 }}
        >
          Log In
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
