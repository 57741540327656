import * as React from "react";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  FormControlLabel,
  Switch,
  Typography, // Assuming you'll need it for rendering text
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  fetchProducts,
  setSelectedProductForDeletion,
  setSelectedProductForEdition,
} from "../app/features/products/productSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Product } from "../Constants/Interfaces";

interface Data {
  photo: string;
  itemId: number;
  itemName: string;
  category: string;
  inStock: string;
  weight: number;
  price: number;
  updatedAt: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data | "edit" | "delete";
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  sortable?: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "photo",
    numeric: false,
    disablePadding: true,
    label: "Photo",
    sortable: false,
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: false,
    label: "Item Id",
    sortable: true,
  },
  {
    id: "itemName",
    numeric: true,
    disablePadding: false,
    label: "Item Name",
    sortable: true,
  },
  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Category",
    sortable: true,
  },
  {
    id: "inStock",
    numeric: true,
    disablePadding: false,
    label: "In Stock",
    sortable: false,
  },
  {
    id: "weight",
    numeric: true,
    disablePadding: false,
    label: "Weight",
    sortable: true,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
    sortable: true,
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    sortable: false,
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: false,
    label: "Delete",
    sortable: false,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data | "edit" | "delete") =>
    (event: React.MouseEvent<unknown>) => {
      const isSortable = headCells.find(
        (headCell) => headCell.id === property
      )?.sortable;
      if (isSortable) {
        onRequestSort(event, property as keyof Data); // Ensure onRequestSort only called with Data keys
      }
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export interface CustomizeItemsProps {
  handleOpen: () => void;
  handleDeleteOpen: () => void;
  searchTerm: string;
}

export default function CustomizeItemsTable({
  handleOpen,
  handleDeleteOpen,
  searchTerm,
}: CustomizeItemsProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("updatedAt");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.products);
  const productStatus = useAppSelector((state) => state.products.status);

  React.useEffect(() => {
    if (productStatus === "idle") {
      dispatch(fetchProducts());
    }
  }, [productStatus, dispatch]);

  // Assuming you have a default image in the public folder or similar
  const defaultImage = "./default.jpg";

  const rows: Data[] = products.map((product) => {
    const rateCardItems = Object.values(product.rateCard);
    let defaultItem = rateCardItems.find((item) => item.default === true);
    if (!defaultItem && rateCardItems.length > 0) {
      // If no default item is found, use the first item
      defaultItem = rateCardItems[0];
    }

    return {
      photo: product.image || defaultImage,
      itemId: product.productCode,
      itemName: product.name,
      category: product.category || "N/A", // Assuming you also want a fallback for category
      inStock: product.isInStock ? "true" : "false",
      weight: defaultItem ? defaultItem.name : "N/A", // Using 'name' for weight as per your requirement
      price: defaultItem ? defaultItem.price.toString() : "N/A", // Converting price to string, assuming Data.price is a string
      updatedAt: product.updatedAt || "N/A",
    };
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.itemId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const filteredRows = React.useMemo(() => {
    if (!searchTerm) return rows;
    return rows.filter(
      (row) =>
        row.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.itemId.toString().includes(searchTerm)
    );
  }, [rows, searchTerm]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows] // Update dependency to filteredRows
  );

  // if (visibleRows.length === 0) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         width: "100%",
  //         height: "100vh",
  //         position: "absolute",
  //         top: 0,
  //         left: 0,
  //       }}
  //     >
  //       <Typography variant="subtitle1" component="div">
  //         No products to display
  //       </Typography>
  //     </Box>
  //   );
  // }

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.length > 0 ? (
                visibleRows.map((row, index) => (
                  <TableRow role="checkbox" tabIndex={-1} key={row.itemId}>
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      <img
                        src={row.photo}
                        alt={row.itemName}
                        width="50"
                        height="50"
                      />
                    </TableCell>
                    <TableCell align="left">{row.itemId}</TableCell>
                    <TableCell align="left">{row.itemName}</TableCell>
                    <TableCell align="left">{row.category}</TableCell>
                    <TableCell align="left">{row.inStock}</TableCell>
                    <TableCell align="left">{row.weight}</TableCell>
                    <TableCell align="left">{row.price}</TableCell>
                    <TableCell align="left">
                      <EditOutlinedIcon
                        style={{ color: "#C72128", cursor: "pointer" }}
                        onClick={() => {
                          const productToEdit = products.find(
                            (product) => product.productCode === row.itemId
                          );
                          if (productToEdit) {
                            dispatch(
                              setSelectedProductForEdition(productToEdit)
                            );
                            handleOpen();
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <DeleteOutlinedIcon
                        style={{ color: "#C72128", cursor: "pointer" }}
                        onClick={() => {
                          const productToDelete = products.find(
                            (product) => product.productCode === row.itemId
                          );
                          if (productToDelete) {
                            dispatch(
                              setSelectedProductForDeletion(productToDelete)
                            );
                            handleDeleteOpen();
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No items to display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
