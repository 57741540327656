import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Refresh from "@mui/icons-material/Refresh";

import DangerousIcon from "@mui/icons-material/Dangerous";
import { useAppSelector } from "../../app/hooks";
import { IconButton, Stack } from "@mui/material";
import usePrinter from "../../app/hooks/usePrinterConfiguration";

const PrinterStatus = () => {
  //   const printerSettings = useAppSelector(
  //     (state) => state.settings.selectedPrinter
  //   );

  const { selectedPrinter, establishPrinterConnection } = usePrinter();

  return (
    <Stack flexDirection={"row"}>
      <LoadingButton
        loading={false}
        loadingPosition="start"
        startIcon={
          selectedPrinter.isPrinterConfigured ? (
            <CheckCircle />
          ) : (
            <DangerousIcon />
          )
        }
        variant="text"
        //onClick={saveConnection}
      >
        {selectedPrinter.isPrinterConfigured
          ? "Printer Connected"
          : "Printer Disnonnected"}
      </LoadingButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => establishPrinterConnection()}
      >
        <Refresh fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default PrinterStatus;
