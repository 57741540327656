import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
  Button,
  Grid,
  Badge,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ItemCard from "../../components/ItemCard";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchProducts } from "../../app/features/products/productSlice";
import BillingComponent from "../../components/BillingComponent/BillingComponent";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export interface Props {
  handleOpen: () => void;
}

const Items: React.FC<Props> = ({ handleOpen }) => {
  const [dropdownValue, setDropdownValue] = useState<string | number>("");
  const [hideCart, setHideCart] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  //state
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.products);
  const productStatus = useAppSelector((state) => state.products.status);
  const error = useAppSelector((state) => state.products.error);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const { categories } = useAppSelector((state) => state.products);

  useEffect(() => {
    if (productStatus === "idle") {
      dispatch(fetchProducts());
    }
  }, [productStatus, dispatch]);

  const handleDropdownChange = (
    event: SelectChangeEvent<typeof dropdownValue>
  ) => {
    setDropdownValue(event.target.value as typeof dropdownValue);
  };

  const filteredProducts = products.filter((product) => {
    const query = searchQuery.toLowerCase();

    const productCodeMatch = product.productCode.toString().includes(query);
    const nameMatch = product.name.toLowerCase().includes(query);
    const categoryMatch = product.category.toLowerCase().includes(query);

    const categoryFilter = dropdownValue
      ? product.category === dropdownValue
      : true;

    return (productCodeMatch || nameMatch || categoryMatch) && categoryFilter;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "60%",
          }}
        >
          <TextField
            label="Search Item No, Name or Category"
            variant="outlined"
            sx={{ width: "calc(50% - 8px)" }}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl variant="outlined" sx={{ width: "calc(50% - 8px)" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              value={dropdownValue}
              onChange={handleDropdownChange}
              label="Categories"
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {categories.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // column on xs, row on md and up
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 1,
          width: "100%",
          mt: { xs: 2, sm: 0 },
        }}
      >
        <Box
          sx={{
            flex: 1,
            ml: "auto",
            width: "384px",
            display: "flex",
            justifyContent: "end",
            mt: 3,
            order: { xs: 1, sm: 2 }, // Appears second on medium and larger screens
          }}
        >
          <BillingComponent handleOpen={handleOpen} />
        </Box>

        {/* This Grid should follow the Box on extra small screens */}
        <Grid
          container
          spacing={2}
          sx={{
            flex: 4,
            mt: 2,
            mr: 2,
            order: { xs: 2, sm: 1 },
            maxHeight: { xs: "600px", sm: "600px" },
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "8px", // Thin width
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey", // Smooth track
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "darkgrey", // Scrollbar color
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#b30000", // Color when hovered
            },
            // Firefox scrollbar styles
            scrollbarWidth: "thin",
            scrollbarColor: "darkgrey #ffffff", // scrollbar thumb and track color
          }}
        >
          {filteredProducts.length > 0 &&
            filteredProducts.map((product) => (
              <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={product._id}>
                <ItemCard handleOpen={handleOpen} item={product} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Items;
