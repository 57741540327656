import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Product } from "../Constants/Interfaces";
import { setSelectedProductForBilling } from "../app/features/products/productSlice";
import { useAppDispatch } from "../app/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

export interface ItemCardProps {
  handleOpen: () => void;
  item: Product;
}

export default function ItemCard({ handleOpen, item }: ItemCardProps) {
  //state
  const dispatch = useAppDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);

  console.log("cartItems", cartItems);
  console.log("item", item);

  const itemColor = cartItems.some(
    (cartItem) => cartItem.id.split("-")[0] === item._id
  )
    ? "#E8BD4A"
    : "white";

  const isNotAvailableStyle = {
    filter: "grayscale(100%)",
    pointerEvents: "none",
    backgroundColor: "#f0f0f0",
  };

  return (
    <Card
      onClick={() => {
        dispatch(setSelectedProductForBilling(item));
        handleOpen();
      }}
      sx={{
        backgroundColor: itemColor,
        ...(item?.isInStock ? {} : isNotAvailableStyle),
        display: "flex", // Make the card a flex container
        flexDirection: "column", // Orient children vertically
        height: "100%", // Ensure it fills the container height
        boxShadow: "0px 2px 4px rgba(0, 0, 1, 0.2)",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={item.image ? item.image : "./default.jpg"}
          alt={item.name}
        />
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingY: "10px",
            "&:last-child": { paddingBottom: "8px" },
          }}
        >
          <Box>
            <Typography
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold", fontSize: "1rem", lineHeight: 1.5 }}
            >
              {item.name}
            </Typography>
            <Typography
              color="black"
              sx={{ fontSize: "0.875rem", lineHeight: 1.5 }}
            >
              {item.productCode}
            </Typography>
          </Box>
          <AddIcon
            sx={{
              backgroundColor: "#C72128",
              borderRadius: 0.6,
              color: "white",
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
