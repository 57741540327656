import { useEffect, useState } from "react";
import {
  establishConnect,
  testPrinter,
} from "../../Pages/Settings/Printer/helper";
import {
  fetchClientInfo,
  setConnectionInfo,
  setIsPrinterConnected,
  updatePrinterSettings,
} from "../features/settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../hooks";

const usePrinter = () => {
  const dispatcher = useAppDispatch();
  const clientStatus = useAppSelector((state) => state.settings.status);
  const selectedPrinter = useAppSelector(
    (state) => state.settings.selectedPrinter
  );

  const [printerStatus, setPrinterStatus] = useState(
    selectedPrinter.isPrinterConfigured
  );

  const saveConnection = (vendorId: string, productId: string) => {
    try {
      dispatcher(setConnectionInfo({ productId, vendorId }));
      if (selectedPrinter) {
        dispatcher(
          updatePrinterSettings({
            ...selectedPrinter,
            productId,
            vendorId,
            connectionSettings: {
              ...selectedPrinter.connectionSettings,
              productId,
              vendorId,
            },
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const establishPrinterConnection = async (
    vendorId = selectedPrinter.connectionSettings.vendorId,
    productId = selectedPrinter.connectionSettings.productId
  ) => {
    try {
      const isPrinterConnect = await establishConnect(vendorId, productId);
      dispatcher(setIsPrinterConnected(isPrinterConnect));
      setPrinterStatus(isPrinterConnect);
    } catch (e) {
      console.log(e);
    }
  };

  const testPrinting = async () => {
    await testPrinter();
  };

  useEffect(() => {
    if (clientStatus === "idle") {
      dispatcher(fetchClientInfo());
    } else {
      establishPrinterConnection();
    }
  }, [clientStatus, dispatcher]);

  return {
    selectedPrinter,
    printerStatus,
    saveConnection,
    establishPrinterConnection,
    testPrinting,
  };
};

export default usePrinter;
