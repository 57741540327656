import { ItemRecord } from "../../../app/hooks/useThermalPrinter";
import { IDeviceConfigureList } from "./ptypes";

// Function to list USB devices
const listUSBDevices = async (content: string) => {
  try {
    // @ts-ignore
    const MyPrinter = window.electron.printerOptions;
    const devices = MyPrinter.getPrinters();
    console.log(devices);
    const printers = MyPrinter.connectViaUSB(
      devices[0].deviceDescriptor.idVendor,
      devices[0].deviceDescriptor.idProduct
    );
    MyPrinter.print(content);
    console.log(devices, printers);
    return devices;
  } catch (error) {
    console.error("Error listing USB devices:", error);
  }
};

const establishConnect = (vId: string, pId: string) => {
  try {
    // @ts-ignore
    const MyPrinter = window.electron.printerOptions;
    MyPrinter.connectViaUSB(vId, pId);
    return MyPrinter.checkPrinterConnection();
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Function to list USB devices
const getPrinterConfigList = (): IDeviceConfigureList => {
  try {
    // @ts-ignore
    const MyPrinter = window.electron.printerOptions;
    const devices = MyPrinter.getPrinters();
    return devices;
  } catch (error) {
    console.error("Error listing USB devices:", error);
    return [];
  }
};

const printBill = (
  billNo: number,
  billString: string,
  billItems: { [key: string]: ItemRecord[] },
  totalAmount: Number,
  discountInPercentage: Number,
  discountAmount: Number
) => {
  // @ts-ignore
  const MyPrinter = window.electron.printerOptions;
  MyPrinter.print(
    billNo,
    billString,
    billItems,
    totalAmount,
    discountInPercentage,
    discountAmount
  );
};

const getPrintersList = async () => {
  try {
    // @ts-ignore
    return await securePOSPrinter?.getPrinters();
  } catch (e) {
    console.log(e);
    return [];
  }
};

const testPrinter = async () => {
  try {
    // @ts-ignore
    const MyPrinter = window.electron.printerOptions;
    await MyPrinter.testPrint();
  } catch (error) {
    console.error("Error printing test from printer", error);
  }
};

export {
  establishConnect,
  getPrinterConfigList,
  getPrintersList,
  listUSBDevices,
  printBill,
  testPrinter,
};
