import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../hooks";

const getConversionRate = (type: string) => {
  switch (type) {
    case "KG":
      return 1000;
    case "LITER":
      return 1000;
    default:
      return 1;
  }
};

const getConversionFamily = (type: string) => {
  switch (type) {
    case "KG":
    case "GRAMS":
      return 1;
    case "LITER":
    case "MILLI-LITER":
      return 2;
    default:
      return 3;
  }
};

const useItemController = () => {
  const [defaultMetric, setDefaultmetric] = useState("NONE");

  const [perPrice, setPerPrice] = useState(0);

  const selectedProduct = useAppSelector(
    (state) => state.products.selectedProductForBilling
  );

  const getConversionValue = (quantity: number, quantityType: string) => {
    return Number(quantity) * Number(getConversionRate(quantityType));
  };

  const getDefaultRateCard = useCallback(() => {
    const rateCards = Object.values(selectedProduct?.rateCard || {});
    const dCard =
      rateCards.find((rateCard) => rateCard.default) || rateCards[0] || null;
    if (dCard) {
      setDefaultmetric(dCard.quantityType);
      const perPriceVal =
        Number(dCard.price) /
        (Number(dCard.quantity) *
          Number(getConversionRate(dCard.quantityType)));
      setPerPrice(perPriceVal);
    }
  }, [selectedProduct]);

  const getCustomPriceValue = (
    type: string,
    quantity: number,
    isConverted = false
  ) => {
    const val = isConverted ? quantity : getConversionValue(quantity, type);
    if (getConversionFamily(defaultMetric) === getConversionFamily(type)) {
      return val * perPrice;
    }
    return 0;
  };

  useEffect(() => {
    getDefaultRateCard();
  }, [getDefaultRateCard]);

  return { getCustomPriceValue };
};

export default useItemController;
