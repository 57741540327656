import { RowData } from "./Interfaces";

export const metricOptions = [
    "KG",
    "GRAMS",
    "LITER",
    "MILLI-LITER",
    "SMALL",
    "MEDIUM",
    "LARGE",
    "ITEM",
    "SLICE",
    "PIECE",
  ];

  export const metricAbbreviations = {
    "KG": "kg",
    "GRAMS": "gms",
    "LITER": "li",
    "MILLI-LITER": "ml",
    "SMALL": "sm",
    "MEDIUM": "md",
    "LARGE": "lg",
    "ITEM": "it",
    "SLICE": "sl",
    "PIECE": "pi",
  };

  export const initialRows: RowData[] = [
    { id: "1", mrp: "00", quantity: "0", metric: "KG" },
    // ...more initial rows if necessary
  ];