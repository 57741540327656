export enum FONT_STYLE {
  NORMAL = "NORMAL",
  BOLD = "B",
  ITALIC = "I",
  UNDER_LINE = "U",
  U2 = "U2",
  BI = "BI",
  BIU = "BIU",
  BIU2 = "BIU2",
  BU = "BU",
  BU2 = "BU2",
  IU = "IU",
  IU2 = "IU2",
}

export enum FONT_FAMILY {
  SANS = "A",
  MONO = "B",
}

export enum ALIGN {
  CENTER = "CT",
  RIGHT = "RT",
  LEFT = "LT",
}

export enum TABLEALIGN {
  CENTER = "CENTER",
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

export enum FONT_SIZE {
  SMALL = "0",
  MEDIUM = "1",
  LARGE = "2",
}
