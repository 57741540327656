import React, { useEffect } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import CartComponent from "./CartComponent";
import PrintBillComponent from "./PrintBillComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export interface CartItem {
  id: string;
  image: string; // URL to the product image
  name: string;
  productCode: string;
  quantity: number;
  amount: number;
  quantityType: string;
  rateCardId: string;
  noOfQuantity?: number,
  quantityTypeShort?: string,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface BillingComponentProps {
  handleOpen: () => void;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BillingComponent: React.FC<BillingComponentProps> = ({ handleOpen }) => {
  const [value, setValue] = React.useState(0);
  const cartItems = useSelector((state: RootState) => state.cart.items);

  useEffect(() => {
    if (cartItems.length === 0) {
      setValue(0);
    }
  }, [cartItems]);

  return (
    <Box
      sx={{
        width: "384px",
        border: 1,
        borderRadius: 2,
        backgroundColor: "#fffff",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} aria-label="basic tabs example">
          <Tab label="Cart" {...a11yProps(0)} />
          <Tab label="Billing" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CartComponent changeTab={() => setValue(1)} handleOpen={handleOpen} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="printable">
          <PrintBillComponent handleCancel={() => setValue(0)} />
        </div>
      </CustomTabPanel>
    </Box>
  );
};

export default BillingComponent;
