import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  IconButton,
  FormControlLabel,
  Radio,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { initialRows, metricOptions } from "../../Constants/Constants";
import {
  CustomizeQuantityAndRatesTableProps,
  RateCardItemCustomize,
  RowData,
} from "../../Constants/Interfaces";

const Header: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" gap={1} mb={1} fontWeight="bold">
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>MRP</Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "left" }}>
        Quantity
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "left" }}>
        Metric
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        Delete
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        Default
      </Box>
    </Box>
  );
};

const RowComponent: React.FC<{
  row: RateCardItemCustomize;
  selectedRowId: string | null;
  onChange: (
    id: string,
    field: keyof RateCardItemCustomize,
    value: string
  ) => void;
  onRadioChange: (id: string) => void;
  onDelete: (id: string) => void;
}> = ({ row, selectedRowId, onChange, onRadioChange, onDelete }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around" // Ensures equal spacing around items
      gap={1}
      mb={2}
    >
      <TextField
        size="small"
        value={row.price.toString()}
        onChange={(e) => onChange(row._id, "price", e.target.value)}
        sx={{ flex: 1 }}
        InputProps={{
          style: { textAlign: "center" },
        }}
      />

      <TextField
        size="small"
        value={row.quantity}
        onChange={(e) => onChange(row._id, "quantity", e.target.value)}
        sx={{ flex: 1 }}
        InputProps={{
          style: { textAlign: "center" },
        }}
      />

      <Select
        size="small"
        value={row.quantityType}
        onChange={(e) =>
          onChange(row._id, "quantityType", e.target.value as any)
        }
        sx={{ flex: 1 }}
        renderValue={(selectedValue) => `${selectedValue}`.slice(0, 2)}
      >
        {metricOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <IconButton
        onClick={() => onDelete(row._id)}
        sx={{ flex: 1, justifyContent: "center" }}
      >
        <DeleteIcon />
      </IconButton>

      <FormControlLabel
        control={
          <Radio
            checked={row.default}
            onChange={() => onRadioChange(row._id)}
            size="small"
          />
        }
        label=""
        sx={{ flex: 1, justifyContent: "center" }}
      />
    </Box>
  );
};

const CustomizeQuantityAndRatesTable: React.FC<
  CustomizeQuantityAndRatesTableProps
> = ({ rows, setRows }) => {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(
    initialRows[0].id
  );

  const handleChange = (
    id: string,
    field: keyof RateCardItemCustomize,
    value: string
  ) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row._id === id) {
          return { ...row, [field]: value };
        }
        return row;
      });
    });
  };
  const handleRadioChange = (id: string) => {
    setSelectedRowId(id);
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row._id === id) {
          return { ...row, default: true }; // Set to true for the selected row
        }
        // For all other rows, set default to false instead of deleting
        return { ...row, default: false };
      });
    });
  };

  const handleAddRow = () => {
    const newRowId = `row_${Date.now()}`; // Example for a unique ID
    const newRow: RateCardItemCustomize = {
      _id: newRowId,
      price: 0,
      quantity: "",
      quantityType: "",
      name: "",
      default: false,
    };
    setRows((prevRows) => [...prevRows, newRow]);
    setSelectedRowId(newRowId); // Optionally select new row by default
  };

  // handleDeleteRow filters out the row to be deleted by its ID
  const handleDeleteRow = (id: string) => {
    setRows((prevRows) => prevRows.filter((row) => row._id !== id));
    if (selectedRowId === id) {
      setSelectedRowId(null); // If the deleted row was selected, clear the selection
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "end", mb: 2 }}
      >
        <Button variant="outlined" onClick={handleAddRow}>
          Add Price
        </Button>
      </Box>
      <Header />
      {rows.map((row) => (
        <RowComponent
          key={row._id}
          row={row}
          selectedRowId={selectedRowId}
          onChange={handleChange}
          onRadioChange={handleRadioChange}
          onDelete={handleDeleteRow}
        />
      ))}
    </Box>
  );
};

export default CustomizeQuantityAndRatesTable;
