import * as React from "react";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CartItem } from "../BillingComponent/BillingComponent";
import { addItemToCart } from "../../app/features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  clearItemForBilling,
  clearSelectedProductForBilling,
} from "../../app/features/products/productSlice";
import { metricOptions } from "../../Constants/Constants";
import { ImageContainer } from "./DeleteItemModal";
import uuid from "react-uuid";
import useItemController from "../../app/hooks/useItemController";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function SaleItemModal({
  open,
  handleClose: propsHandleClose,
}: Props) {
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state: RootState) => state.products.selectedProductForBilling
  );
  const editItem = useSelector(
    (state: RootState) => state.products.editProductForBilling
  );

  const { getCustomPriceValue } = useItemController();

  const [selectedOption, setSelectedOption] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [metric, setMetric] = React.useState("");
  const [customAmount, setCustomAmount] = React.useState("");
  const [customQuantity, setCustomQuantity] = React.useState("");
  const [customMetric, setCustomMetric] = React.useState("");
  const [submitError, setSubmitError] = React.useState<string>("");
  const [rateCardId, setRateCardId] = React.useState<string>("");

  React.useEffect(() => {
    if (selectedProduct) {
      const defaultRateCard = getDefaultRateCard();
      if (defaultRateCard) {
        setCustomQuantity(defaultRateCard.quantity);
        setCustomAmount(defaultRateCard.price);
        setCustomMetric(defaultRateCard.quantityType);
        setQuantity(defaultRateCard.quantity);
        setAmount(defaultRateCard.price);
      }
    }
  }, [selectedProduct]);

  React.useEffect(() => {
    setCustomAmount(
      getCustomPriceValue(customMetric, Number(customQuantity)).toString()
    );
  }, [customMetric, customQuantity]);

  // Handle selection of rate card or custom option
  const handleOptionSelect = (optionName: string) => {
    setSelectedOption(optionName);
    const defaultRateCard = getDefaultRateCard();
    setQuantity(defaultRateCard?.quantity || "");
    setAmount(defaultRateCard?.price || "");
    setMetric(defaultRateCard?.quantityType || "");
  };

  // Reset function
  const resetState = () => {
    setSelectedOption("");
    setQuantity("");
    setAmount("");
    setMetric("");
  };

  const isNotAvailableStyle = {
    filter: "grayscale(100%)",
    pointerEvents: "none",
    backgroundColor: "#f0f0f0",
  };

  const handleDone = () => {
    setSubmitError("");

    if (selectedOption === "custom") {
      if (customQuantity === "" || customAmount === "" || customMetric === "") {
        setSubmitError(
          "Please select a value before adding the product to the cart."
        );
        return;
      } else {
        setSubmitError("");
      }
    }

    if (selectedProduct) {
      const newItem: CartItem = {
        id: editItem ? editItem.id : `${selectedProduct._id}-${uuid()}`,
        productCode: selectedProduct.productCode.toString(),
        image: selectedProduct.image,
        name: selectedProduct.name,
        quantity: parseInt(quantity),
        amount:
          selectedOption === "custom"
            ? parseInt(customAmount)
            : parseInt(amount),
        quantityType: selectedOption === "custom" ? customMetric : metric,
        rateCardId: rateCardId,
      };
      dispatch(addItemToCart(newItem));
      dispatch(clearSelectedProductForBilling());
      dispatch(clearItemForBilling());
      propsHandleClose();
      resetState();
    }
  };

  const handleClose = () => {
    resetState();
    propsHandleClose();
    setSubmitError("");
  };

  const getDefaultRateCard = () => {
    const rateCards = Object.values(selectedProduct?.rateCard || {});
    return (
      rateCards.find((rateCard) => rateCard.default) || rateCards[0] || null
    );
  };
  const defaultRateCard = getDefaultRateCard();

  const handleIncrement = () => {
    setSubmitError("");
    if (selectedOption === "custom") {
      if (
        quantity === "" ||
        amount === "" ||
        customQuantity === "" ||
        customAmount === ""
      ) {
        setSubmitError(
          "Please select a value before adding the product to the cart."
        );
        return;
      }
    }
    if (selectedOption === "custom") {
      setQuantity((prev: string) =>
        (parseInt(prev, 10) + parseInt(customQuantity, 10)).toString()
      );
      setAmount((prev: string) =>
        (parseInt(prev, 10) + parseInt(customAmount, 10)).toString()
      );
      return;
    }
    Object.values(selectedProduct?.rateCard as { [key: string]: any }).forEach(
      (rateCard) => {
        if (rateCard._id === rateCardId) {
          setQuantity((prev: string) =>
            (parseInt(prev, 10) + parseInt(rateCard.quantity, 10)).toString()
          );
          setAmount((prev: string) =>
            (parseInt(prev, 10) + rateCard.price).toString()
          );
        }
      }
    );
  };

  const handleDecrement = () => {
    setSubmitError("");
    if (selectedOption === "custom") {
      if (
        quantity === "" ||
        amount === "" ||
        customQuantity === "" ||
        customAmount === ""
      ) {
        setSubmitError(
          "Please select a value before adding the product to the cart."
        );
        return;
      }
    }
    if (selectedOption === "custom") {
      setQuantity((prev: string) =>
        (parseInt(prev, 10) - parseInt(customQuantity, 10)).toString()
      );
      setAmount((prev: string) =>
        (parseInt(prev, 10) - parseInt(customAmount, 10)).toString()
      );
      return;
    }
    Object.values(selectedProduct?.rateCard as { [key: string]: any }).forEach(
      (rateCard) => {
        if (rateCard._id === rateCardId) {
          setQuantity((prev: string) => {
            const newValue =
              parseInt(prev, 10) - parseInt(rateCard.quantity, 10);
            return newValue > 0 ? newValue.toString() : "0";
          });
          setAmount((prev: string) => {
            const newValue = parseInt(prev, 10) - rateCard.price;
            return newValue > 0 ? newValue.toString() : "0";
          });
        }
      }
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
        sx={{ marginX: "1rem" }}
      >
        <ModalContent
          sx={{
            width: 400,
            ...(selectedProduct?.isInStock ? {} : isNotAvailableStyle),
          }}
        >
          <ImageContainer>
            <img
              src={selectedProduct?.image || "./default.jpg"}
              alt="Product"
              style={{ width: "100%", height: "100%", objectFit: "fill" }} // Stretch the image to fill the container
            />
          </ImageContainer>
          <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {selectedProduct?.name}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <Typography sx={{ flex: "1 1 calc(50% - 16px)" }}>
              <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                Item Id:
              </span>{" "}
              {selectedProduct?.productCode}
            </Typography>
            <Typography
              sx={{
                flex: "1 1 calc(50% - 16px)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "right",
              }}
            >
              <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                MRP:
              </span>{" "}
              ₹
              {defaultRateCard
                ? `${defaultRateCard.price} / ${defaultRateCard.quantity} ${defaultRateCard.quantityType.toLowerCase().slice(0, 2)}`
                : "N/A"}
            </Typography>

            <Stack flexDirection={"row"} style={{ flex: 1 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1 1 calc(50% - 16px)",
                }}
              >
                <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                  In Stock:
                </span>
                {selectedProduct?.isInStock ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <RemoveCircleOutlineIcon color="error" />
                )}
              </Typography>

              {selectedOption === "custom" ? (
                ""
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      minWidth: "42px",
                      height: "42px",
                      fontSize: "32px",
                      textAlign: "center",
                    }}
                    onClick={handleDecrement}
                    color="error"
                  >
                    -
                  </Button>
                  <Typography
                    variant="body1"
                    sx={{ minWidth: "36px", textAlign: "center" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ minWidth: "36px", textAlign: "center" }}
                    >
                      {selectedOption
                        ? `${quantity} ${metric.toLowerCase().slice(0, 2)}`
                        : "0"}
                    </Typography>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      minWidth: "42px",
                      height: "42px",
                      fontSize: "32px",
                      textAlign: "center",
                    }}
                    onClick={handleIncrement}
                    color="error"
                  >
                    +
                  </Button>
                </Box>
              )}
            </Stack>
          </Box>
          <Typography sx={{ flex: "1 1 calc(50% - 16px)", mt: "0.5rem" }}>
            <span style={{ fontWeight: "bold" }}>select Quantity</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap", // Enable wrapping
              justifyContent: "flex-start", // Align items to the start
              gap: "16px", // Spacing between items
            }}
          >
            {Object.entries(selectedProduct?.rateCard || {}).map(
              ([key, rateCard]) => (
                <Button
                  key={key}
                  variant={
                    selectedOption === rateCard.name ? "contained" : "outlined"
                  }
                  onClick={() => {
                    handleOptionSelect(rateCard.name);
                    setQuantity(rateCard.quantity);
                    setMetric(rateCard.quantityType);
                    setAmount(rateCard.price);
                    setRateCardId(rateCard._id);
                  }}
                  sx={{
                    width: `calc((100% - 32px) / 3)`, // Adjust width to fit 3 items per row considering the gap
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis", // Ellipsis for overflow text
                  }}
                >
                  {rateCard.name}
                </Button>
              )
            )}
            <Button
              variant={selectedOption === "custom" ? "contained" : "outlined"}
              onClick={() => handleOptionSelect("custom")}
              sx={{
                width: `calc((100% - 32px) / 3)`, // Same width as other buttons
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Custom
            </Button>
          </Box>

          {/* CUSTOM BUTTON STARTS HERE */}
          {selectedOption === "custom" && (
            <Box display="flex" sx={{ mt: 2 }}>
              <Box flexBasis="30%" mr={1}>
                <TextField
                  value={customQuantity}
                  onChange={(e) => {
                    setCustomQuantity(e.target.value);
                    setQuantity(e.target.value);
                  }}
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  size="small" // Make the TextField small
                />
              </Box>

              <Box flexBasis="30%" mr={1}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="demo-simple-select-label">Metric</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={customMetric}
                    label="Metric"
                    onChange={(e) => {
                      setCustomMetric(e.target.value);
                      setMetric(e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select metric
                    </MenuItem>
                    {metricOptions.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ fontSize: "0.75rem" }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flexBasis="30%" mr={1}>
                <TextField
                  value={customAmount}
                  onChange={(e) => {
                    setCustomAmount(e.target.value);
                    setAmount(e.target.value);
                  }}
                  label="Price"
                  variant="outlined"
                  size="small" // Make the TextField small
                />
              </Box>
            </Box>
          )}
          {submitError && (
            <Typography
              color="error"
              sx={{ textAlign: "center" }}
              variant="caption"
            >
              {submitError}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Adjusts spacing between buttons
              mt: 2, // Adds margin at the top for some spacing from other elements
            }}
          >
            <Button
              sx={{
                width: "48%", // Same width as the Done button for consistency
                height: "3rem",
                minHeight: "40px",
                // backgroundColor: "#E8BD4A",
                "&:hover": {
                  backgroundColor: "#D6BE96", // Optional: Change on hover
                },
              }}
              onClick={handleClose} // Assuming handleClose is your method to close the modal or handle the cancel action
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "48%", // Adjusted to 48% to ensure they fit in a row with some space between
                height: "3rem",
                minHeight: "40px",
                // backgroundColor: "#E8BD4A",
                "&:hover": {
                  backgroundColor: "#D6BE96", // Optional: Change on hover for better user feedback
                },
              }}
              onClick={handleDone}
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);
