import ForkRightIcon from "@mui/icons-material/CheckCircle";
import PrinterIcon from "@mui/icons-material/Print";
import {
  Card,
  CardContent,
  FormLabel,
  Grid,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { setPrinterInfo } from "../../../../app/features/settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getPrinterConfigList } from "../helper";
import { IPrinter, IPrinterList } from "../ptypes";
import DeviceCard from "./device-card";
import PrinterConnection from "./printer-connection";

const PrinterListComponent: React.FC<{ printers: IPrinterList }> = ({
  printers,
}) => {
  const dispatcher = useAppDispatch();
  const [isHoveredKey, setIsHoveredKey] = useState<null | number>();
  const [selectedKey, setSelectedKey] = useState<null | number>();
  const selectedPrinter = useAppSelector(
    (state) => state.settings.selectedPrinter
  );

  const selectDevice = (index: number, printer: IPrinter) => {
    setSelectedKey(index);
    dispatcher(setPrinterInfo({ name: printer?.displayName }));
  };

  useEffect(() => {
    if (selectedPrinter) {
      for (let i = 0; i < printers.length; i++) {
        if (selectedPrinter.name === printers[i].displayName) {
          setSelectedKey(i);
          break;
        }
      }
    }
  }, [selectedPrinter, printers]);

  return (
    <Stack>
      <FormLabel component="legend">List of Devices</FormLabel>
      <Grid container>
        {printers.map((printer, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={3}
            lg={3}
            padding={2}
            style={{ position: "relative" }}
          >
            {" "}
            {/* Adjust the grid size as needed */}
            <Card
              style={{
                cursor: "pointer",
                boxShadow:
                  isHoveredKey === index ? "5px 5px 10px #888888" : "none",
                backgroundColor:
                  selectedKey === index
                    ? "#F9DB89"
                    : isHoveredKey === index
                      ? "#b5b5b5"
                      : "white",
              }}
              onMouseEnter={() => setIsHoveredKey(index)}
              onMouseLeave={() => setIsHoveredKey(null)}
              onClick={() => {
                selectDevice(index, printer);
              }}
            >
              <CardContent>
                <Stack flexDirection={"row"} gap={2}>
                  <Stack>
                    <ListItemIcon style={{ fontSize: 50 }}>
                      <PrinterIcon fontSize="inherit" />
                    </ListItemIcon>
                  </Stack>
                  <Stack>
                    <Typography variant="h6" component="h2">
                      {printer.displayName}
                    </Typography>
                    {/* <Typography color="textSecondary" gutterBottom>
                      {printer.name}
                    </Typography> */}
                    <Typography variant="body2" component="p">
                      {printer.options["printer-make-and-model"]}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            {selectedKey === index && (
              <ListItemIcon
                style={{
                  position: "absolute",
                  top: 30,
                  right: -10,
                  fontSize: 20,
                  color: "green",
                }}
              >
                <ForkRightIcon fontSize="inherit" />
              </ListItemIcon>
            )}
          </Grid>
        ))}
      </Grid>
      <Stack>
        <FormLabel component="legend">Device Configuration</FormLabel>
        <Grid container>
          {getPrinterConfigList()?.map((device, index) => (
            <Grid item key={index} xs={12} sm={6} md={3} lg={3} padding={2}>
              <DeviceCard key={index} device={device} index={index} />
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack>
        <PrinterConnection />
      </Stack>
    </Stack>
  );
};

export default PrinterListComponent;
