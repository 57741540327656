export async function login(userId: string, password: string) {
    const response = await fetch('https://billdesk.apis.lightworks-services.com/v1/admin/private/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, password }),
    });
    if (!response.ok) {
      throw new Error('Login failed');
    }
    return response.json();
  }