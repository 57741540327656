import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps } from '@mui/system';

// Extend the interface to include the sx prop
interface ResponsiveTypographyProps extends TypographyProps {
  children: React.ReactNode;
  fontSize?: {
    xs: string;
    lg: string;
  };
  className?: string;
  sx?: SxProps; // Include the sx prop
}

const ResponsiveTypography: React.FC<ResponsiveTypographyProps> = ({ children, fontSize, className, sx, ...otherProps }) => {
  return (
    <Typography
      className={className}
      sx={{ ...sx, fontSize: fontSize, color: '#C72128', ...otherProps }} // Spread the sx prop and any other props
    >
      {children}
    </Typography>
  );
};

export default ResponsiveTypography;
