// src/features/cart/cartSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem } from '../../../components/BillingComponent/BillingComponent';

interface CartState {
  items: CartItem[];
  isBillPrintModalOpen: boolean;
  
}

const initialState: CartState = {
  items: [],
  isBillPrintModalOpen: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action: PayloadAction<CartItem>) => {
      const existingIndex = state.items.findIndex(item => item.id === action.payload.id);
      //state.items.push(action.payload);

      if (existingIndex !== -1) {
        
        state.items[existingIndex] = action.payload;
      } else {
        
        state.items.push(action.payload);
      }
    },
    removeItemFromCart: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(item => item.id !== action.payload);
    },
    clearCart: (state) => {
      state.items = [];
    },
    openBillPrintModal: (state) => {
      state.isBillPrintModalOpen = true; 
    },
    closeBillPrintModal: (state) => {
      state.isBillPrintModalOpen = false; 
    },
  },
});

export const { addItemToCart, removeItemFromCart, clearCart,openBillPrintModal, closeBillPrintModal } = cartSlice.actions;

export default cartSlice.reducer;
