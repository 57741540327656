import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CustomizeItemsTable from "../../components/CustomizeItemsTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React from "react";
import { openAddCategoryModal } from "../../app/features/products/productSlice";

export interface Props {
  handleOpen: () => void;
  handleDeleteOpen: () => void;
  handleAddItem: () => void;
}

const Customize = ({ handleOpen, handleDeleteOpen, handleAddItem }: Props) => {
  const products = useAppSelector((state) => state.products.products);
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  console.log("products", products);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Column on extra small screens, row on small and larger
          gap: 2, // Adds space between items
          alignItems: "center", // Aligns items vertically in the center when in row layout
          justifyContent: "space-between", // Distributes space between items
        }}
      >
        <TextField
          label="Search Item No, Name or Category"
          variant="outlined"
          sx={{ width: { xs: "100%", sm: "30%" } }}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Typography sx={{ flexGrow: 1, textAlign: "center" }}>
          Total Items: {products.length}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#C72128",
            "&:hover": { backgroundColor: "#a71d22" },
          }}
          onClick={() => dispatch(openAddCategoryModal())}
        >
          Add Category
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#C72128",
            "&:hover": { backgroundColor: "#a71d22" },
          }}
          onClick={handleAddItem}
        >
          Add Item
        </Button>
      </Box>
      {/* {products.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Typography variant="subtitle1" component="div">
            No products to display
          </Typography>
        </Box>
      ) : } */}
      <CustomizeItemsTable
        handleOpen={handleOpen}
        handleDeleteOpen={handleDeleteOpen}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default Customize;
