import { Order } from "../../Constants/Interfaces";
import { printBill } from "../../Pages/Settings/Printer/helper";
import { useAppSelector } from "../hooks";

export type ItemRecord = {
  text: string;
  align: "LEFT" | "CENTER" | "RIGHT";
  width: number;
};

const useThermalPrinter = () => {
  const currentOrder: Order | null = useAppSelector(
    (state) => state.orders.currentOrder
  );

  const printCurrentBill = () => {
    try {
      if (currentOrder !== null) {
        const billItems = getItemRecords();
        console.log(currentOrder);
        printBill(
          currentOrder.sNo,
          currentOrder.createdAt,
          billItems,
          currentOrder.totalAmount,
          currentOrder.discount.discountInPercentage,
          currentOrder.discount.discountAmount
        );
      }
    } catch (e) {
      console.log("Error: While Printying the bill...");
    }
  };

  const getItemRecords = () => {
    const data: { [key: string]: ItemRecord[] } = {};
    const SPACE = " ";
    try {
      currentOrder?.items.forEach((item: any, index) => {
        data[String(index)] = [
          { text: String(item.sno) + SPACE, align: "RIGHT", width: 0.1 },
          { text: SPACE + String(item.itemName), align: "LEFT", width: 0.5 },
          {
            text:
              String(item.quantity) +
              String(item.quantityType.toLowerCase().slice(0, 2)) +
              SPACE,
            align: "RIGHT",
            width: 0.2,
          },
          { text: String(item.amount), align: "LEFT", width: 0.2 },
        ];
      });
    } catch (e) {
      console.log("Error while preparing items to print...");
    }
    return data;
  };
  return { printCurrentBill };
};

export default useThermalPrinter;
