import React from "react";
import { Box, IconButton, Typography, Divider, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  removeItemFromCart,
} from "../../app/features/cart/cartSlice";
import DottedLine from "../Molecules/DottedLine";
import { CartItem } from "./BillingComponent";
import { useAppSelector } from "../../app/hooks";
import { Product } from "../../Constants/Interfaces";
import { setEditProductForBilling, setSelectedProductForBilling } from "../../app/features/products/productSlice";

const CartComponent = ({
  changeTab,
  handleOpen,
}: {
  changeTab: () => void;
  handleOpen: () => void;
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const products = useAppSelector((state) => state.products.products);

  const handleEditIcon = (item: CartItem) => {
    // Use the find method to get the first product that matches the condition
    const matchedProduct = products.find(
      (product: Product) => product._id === item.id.split("-")[0]
    );

    if (matchedProduct) {
      dispatch(setSelectedProductForBilling(matchedProduct));
      dispatch(setEditProductForBilling(item));
      handleOpen();
    } else {
      console.log("No product matched the given cart item's ID.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          {/* Adjusted header section to match the items layout */}
          <Typography
            sx={{
              flex: 1,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            S.No
          </Typography>
          <Typography
            sx={{
              flex: 2,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Item
          </Typography>
          <Typography
            sx={{
              flex: 1,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Qty
          </Typography>
          <Typography
            sx={{
              flex: 1,
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Amount
          </Typography>
          <Typography
            sx={{
              flex: 2,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Actions
          </Typography>
        </Box>
        {/* Assuming DottedLine is a component you have that renders a styled divider */}
        <DottedLine />
        {cartItems.length === 0 ? (
          <Typography textAlign="center">No Items Added to the cart</Typography>
        ) : (
          cartItems.map((item, index) => (
            <Box
              key={item.productCode}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center", // Center items vertically within each row
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ flex: 1, textAlign: "left", fontSize: "12px" }}>
                {index + 1}
              </Typography>
              <Typography sx={{ flex: 2, textAlign: "left", fontSize: "12px" }}>
                {item.name}
              </Typography>
              <Typography
                sx={{ flex: 1, textAlign: "center", fontSize: "12px" }}
              >
                {item.quantity}
              </Typography>
              <Typography
                sx={{ flex: 1, textAlign: "center", fontSize: "12px" }}
              >
                {item.amount}
              </Typography>
              <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => handleEditIcon(item)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    dispatch(removeItemFromCart(item.id));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))
        )}
      </Box>

      <Box sx={{ mb: 2 }}>
        <Divider orientation="horizontal" flexItem />
        <Typography textAlign="center" sx={{ mt: 2 }}>
          Total Price:{" "}
          <b>INR {cartItems.reduce((acc, val) => acc + val.amount, 0)}</b>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#E8BD4A" }}
            onClick={() => dispatch(clearCart())}
            disabled={!cartItems.length}
          >
            Clear Cart
          </Button>
          <Button
            variant="contained"
            onClick={changeTab}
            sx={{ backgroundColor: "#E8BD4A" }}
            disabled={!cartItems.length}
          >
            Go to Billing
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CartComponent;
