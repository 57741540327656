import * as React from "react";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearSelectedProductForDeletion,
  deleteProduct,
} from "../../app/features/products/productSlice";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function DeleteItemModal({ open, handleClose }: Props) {
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector(
    (state) => state.products.selectedProductForDeletion
  );

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
        sx={{ marginX: "1rem" }}
      >
        <ModalContent sx={{ width: 400 }}>
          <ImageContainer>
            <img
              src={
                selectedProduct?.image
                  ? selectedProduct?.image
                  : "./default.jpg"
              }
              alt="Product"
              style={{ width: "100%", height: "100%", objectFit: "fill" }} // Stretch the image to fill the container
            />
          </ImageContainer>
          <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {selectedProduct?.name}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <Typography sx={{ flex: "1 1 calc(50% - 16px)" }}>
              <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                Item Id:
              </span>{" "}
              {selectedProduct?.productCode}
            </Typography>
            <Typography sx={{ flex: "1 1 calc(50% - 16px)" }}>
              <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                MRP:
              </span>{" "}
              ₹
              {(() => {
                const rateCards = Object.values(
                  selectedProduct?.rateCard || {}
                );
                const defaultRateCard =
                  rateCards.find((rateCard) => rateCard.default) ||
                  rateCards[0];
                if (defaultRateCard) {
                  return `${defaultRateCard.price} / ${defaultRateCard.quantity} ${defaultRateCard.quantityType}`;
                }
                return "N/A";
              })()}
            </Typography>
          </Box>
          <Typography
            sx={{ color: "#C72128", textAlign: "center", mt: "1.5rem" }}
          >
            Are you sure to delete this item?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              sx={{
                width: "45%",
                height: "3rem",
                minHeight: "40px",
                backgroundColor: "#E8BD4A",
              }}
              onClick={() => {
                dispatch(clearSelectedProductForDeletion());
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "45%",
                height: "3rem",
                minHeight: "40px",
                backgroundColor: "#C72128",
                color: "white",
              }}
              onClick={async () => {
                const productId = selectedProduct?._id;
                if (!productId) {
                  console.error("Product ID is missing");
                  return;
                }
                dispatch(deleteProduct(productId));
                handleClose();
              }}
            >
              Delete
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

export const ImageContainer = styled("div")`
  width: 100%; // Take full width of the modal content
  height: 200px; // Fixed height for the image container
  overflow: hidden; // Ensure the image does not overflow the container
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6f9; // A light background color for the container
`;
