import { Box } from "@mui/material";
import PrinterSettings from "./Printer/printer-settings";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React from "react";
import { fetchClientInfo } from "../../app/features/settings/settingsSlice";

const Settings = () => {
  // const dispatch = useAppDispatch();
  // const clientStatus = useAppSelector((state) => state.settings.status);

  // React.useEffect(() => {
  //   if (clientStatus === "idle") {
  //     dispatch(fetchClientInfo());
  //   }
  // }, [clientStatus, dispatch]);

  return (
    <Box>
      <PrinterSettings />
    </Box>
  );
};

export default Settings;
