import { Box, Divider } from "@mui/material";
import React from "react";

const DottedLine = () => {
  return (
    <Box sx={{ width: "100%", my: 2 }}>
      {" "}
      {/* Ensures the divider has some margin and full width */}
      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          border: "none",
          borderTop: "1px dashed #000", // Creates a dashed line
          color: "black", // Sets the color of the divider, if needed
          margin: "auto", // Centers the divider if it's not full width
        }}
      />
    </Box>
  );
};

export default DottedLine;
