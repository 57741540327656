import React, { useState } from "react";
import {
  Card,
  CardContent,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { IDeviceConfigure } from "../ptypes";
import SettingsIcon from "@mui/icons-material/Settings";


interface DeviceCardProps {
  device: IDeviceConfigure;
  index: number;
}

const DeviceCard: React.FC<DeviceCardProps> = ({ device, index }) => {
  const { busNumber, deviceAddress, deviceDescriptor, portNumbers } = device;
  const [isHoveredKey, setIsHoveredKey] = useState<null | number>();

  return (
    <Card
      style={{
        cursor: "pointer",
        boxShadow: isHoveredKey === index ? "5px 5px 10px #888888" : "none",
        backgroundColor: isHoveredKey === index ? "#F9DB89" : "white",
      }}
      onMouseEnter={() => setIsHoveredKey(index)}
      onMouseLeave={() => setIsHoveredKey(null)}
    >
      <CardContent>
        <Stack flexDirection={"row"}>
          <Stack>
            <ListItemIcon style={{ fontSize: 50 }}>
              <SettingsIcon fontSize="inherit" />
            </ListItemIcon>
          </Stack>
          <Stack>
            {/* <Typography variant="h5" component="h2">
              Bus Number: {busNumber}
            </Typography>
            <Typography color="textSecondary">
              Device Address: {deviceAddress}
            </Typography> */}
            <Typography variant="body2" component="p">
              Vendor ID: {deviceDescriptor.idVendor}
            </Typography>
            <Typography variant="body2" component="p">
              Product ID: {deviceDescriptor.idProduct}
            </Typography>
            <Typography variant="body2" component="p">
              Port Numbers: {portNumbers.join(", ")}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
