import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import ResponsiveTypography from "./ResponsiveTypography";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#F5DC99", boxShadow: "none" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              <img
                src="./logo.svg"
                alt="Logo"
                style={{ height: "3rem", marginRight: "0.5rem" }}
              />
              <span
                style={{
                  position: "absolute",
                  top: 1,
                  right: 4,
                  // Adjust these as necessary to position the ® inside the top right corner of the image
                  fontSize: "12px", // Smaller font size for inside the image
                  fontWeight: "bold",
                  color: "#C72128", // Color of the ® to ensure visibility
                  // Removing the transform property to keep the symbol inside
                }}
              >
                ®
              </span>
            </div>
            <Box>
              <ResponsiveTypography
                sx={{
                  fontSize: { xs: "0.5rem", lg: "1rem" },
                  fontFamily: "'Alice', serif", // Apply Alice font
                }}
              >
                K C SIVA REDDY
              </ResponsiveTypography>
              <ResponsiveTypography
                sx={{
                  fontSize: { xs: "0.4rem", lg: "0.8rem" },
                  fontFamily: "'Alice', serif", // Apply Alice font
                }}
              >
                Pure Ghee Sweets
              </ResponsiveTypography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* <Avatar
              alt="Akash Bharath"
              src="/path-to-your-profile-image.jpg"
              sx={{ width: "2rem", height: "2rem", marginRight: "0.5rem" }}
            /> */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              {" "}
              {/* Adjust gap for icon spacing */}
              <IconButton
                onClick={handleProfileMenuOpen}
                size="small"
                sx={{
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                <ResponsiveTypography
                  fontSize={{ xs: "0.8rem", lg: "1rem" }}
                  sx={{
                    lineHeight: "1",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  {localStorage.getItem("role") || "Hello"}
                </ResponsiveTypography>

                <ArrowDropDownIcon sx={{ color: "#C72128" }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top", // Keep as "top" to open downwards from the anchor point
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Navbar;
