import React, { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat); // Use the plugin

interface BasicDatePickerProps {
  label: string;
  value: dayjs.Dayjs | null;
  onChange: (newValue: dayjs.Dayjs | null) => void;
}

const BasicDatePicker: React.FC<BasicDatePickerProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY"
        slots={(params: TextFieldProps) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
