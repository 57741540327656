enum MODULE_NAMES  {
   TEXT = "Text",
   GAP ="Empty Line",
   LINE = "Line",
   IMAGE= "Image",
   SPACE_LINE= "Empty Space Line",
   TABLE="Table"
}

enum MODULE_KEYS  {
   TEXT = "TEXT",
   GAP ="EMPTYLINE",
   LINE = "LINE",
   IMAGE= "IMAGE",
   SPACE_LINE= "EMPTYSPACELINE",
   TABLE="TABLE"
}

export {MODULE_NAMES, MODULE_KEYS};