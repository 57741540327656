import * as React from "react";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearSelectedProductForEdition,
  fetchProducts,
} from "../../app/features/products/productSlice";
import { ImageContainer } from "./DeleteItemModal";
import { RateCardItemCustomize, RowData } from "../../Constants/Interfaces";
import CustomizeQuantityAndRatesTable from "../Table/CustomizeQuantityAndRatesTable";
import { useState } from "react";
import axios from "axios";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function CustomizeItemModal({ open, handleClose }: Props) {
  const [rows, setRows] = React.useState<RateCardItemCustomize[]>([]);

  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector(
    (state) => state.products.selectedProductForEdition
  );
  const { categories, status, error } = useAppSelector(
    (state) => state.products
  );

  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [isInStock, setIsInStock] = useState(true);
  const [image, setImage] = useState("");

  const [nameError, setNameError] = useState<string>("");
  const [productCodeError, setProductCodeError] = useState<string>("");
  const [rowError, setRowError] = useState<string>("");
  const [loadingImage, setLoadingImage] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");

  const products = useAppSelector((state) => state.products.products);

  React.useEffect(() => {
    if (selectedProduct) {
      setProductName(selectedProduct.name);
      setProductCode(selectedProduct.productCode.toString());
      setCategory(selectedProduct.category);
      setDescription(selectedProduct.description);
      setIsInStock(selectedProduct.isInStock);
      setImage(selectedProduct.image);
      setImageUrl(selectedProduct.image);
      if (selectedProduct && selectedProduct.rateCard) {
        const rateCardArray = Object.keys(selectedProduct.rateCard).map(
          (key) => ({
            ...selectedProduct.rateCard[key],
            id: key,
          })
        );
        setRows(rateCardArray);
      }
    }
  }, [selectedProduct]);

  const validateForm = (): boolean => {
    let isValid = true;
    setNameError("");
    setProductCodeError("");

    // Validate name
    if (!productName.trim()) {
      setNameError("Name is mandatory.");
      isValid = false;
    }

    // Validate product code: It should not be empty and must be unique except for the current product
    if (!productCode.trim()) {
      setProductCodeError("Product Code is mandatory.");
      isValid = false;
    } else if (
      products.some(
        (product) =>
          product.productCode.toString() === productCode.trim() &&
          selectedProduct?.productCode.toString() !== productCode.trim()
      )
    ) {
      setProductCodeError("This Product Code already exists for another item.");
      isValid = false;
    }

    return isValid;
  };

  const validateRows = (): boolean => {
    const invalidRow = rows.some(
      (row) => !row.price || !row.quantity || !row.quantityType
    );

    if (invalidRow) {
      setRowError(
        'Each row must have "MRP", "Quantity", and "Metric" filled out or be deleted.'
      );
      return false;
    }

    setRowError("");
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm() || !validateRows()) return;

    if (!selectedProduct) {
      console.error("No product selected");
      return;
    }
    console.log("imageUrl", imageUrl);
    const requestBody = {
      product: {
        name: productName,
        productCode: parseInt(productCode, 10),
        category: category,
        description: description,
        image: imageUrl,
        isInStock: isInStock,
        rateCard: Object.assign(
          {},
          ...rows.map((item, index) => ({
            [index]: {
              default: item.default,
              price: item.price,
              quantity: item.quantity,
              quantityType: item.quantityType,
              name: `${item.quantity} ${item.quantityType}`,
            },
          }))
        ),
      },
    };

    console.log("requestBody", requestBody);

    try {
      const response = await fetch(
        `https://billdesk.apis.lightworks-services.com/v1/admin/private/api/update-product/${selectedProduct._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbklkIjoiYWRtaW4zIiwiaWF0IjoxNzEwMTE4NjI1fQ.OO1BtBwfFvBmJlq19FXexDo7-pX5Zbqk0TR3KX115JM",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.isSuccess) {
        handleClose();
        dispatch(fetchProducts());
        setImageUrl("");
      } else {
        // Handle failure (e.g., show an error message)
        console.error("Failed to update the product");
      }
    } catch (error) {
      console.error("Error updating the product:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      setLoadingImage(true);
      const formData = new FormData();
      formData.append("filename", newFile);

      try {
        // Uploading the file
        const response = await axios.post(
          "https://billdesk.apis.lightworks-services.com/v1/admin/private/api/uploadFile",
          formData,
          {
            headers: {
              // Do not manually set Content-Type for FormData; axios will set it correctly
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("File uploaded successfully:", response.data.downloadURL);
        setImageUrl(response.data.downloadURL);
        setLoadingImage(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoadingImage(false);
      }
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value.toLowerCase(); // Convert new name to lowercase
    const nameExists = products.some(
      (product) =>
        product.name.toLowerCase() === newName &&
        product._id !== selectedProduct?._id
    ); // Compare lowercase values

    if (nameExists) {
      setNameError("Name already exists");
    } else {
      setNameError(""); // Clear any existing error if the new name does not exist among products
    }

    setProductName(event.target.value); // Keep the original input to preserve user's case choice in the input field
  };

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => {
          dispatch(clearSelectedProductForEdition());
          handleClose();
          setImageUrl("");
          setRows([]);
        }}
        slots={{ backdrop: StyledBackdrop }}
        sx={{ marginX: "1rem" }}
      >
        <ModalContent sx={{ width: "auto", maxWidth: "500px", p: 3 }}>
          <Box
            sx={{
              width: "100%",
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #ddd",
              position: "relative",
              overflow: "hidden",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1,
              },
            }}
          >
            {loadingImage ? (
              <CircularProgress
                sx={{
                  position: "relative",
                  zIndex: 2,
                }}
              />
            ) : (
              <>
                <Button
                  component="label"
                  sx={{
                    zIndex: 2,
                    position: "relative",
                    color: "inherit",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    },
                  }}
                >
                  Choose Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                {(imageUrl || image) && (
                  <Box
                    sx={{
                      backgroundImage: imageUrl
                        ? `url(${imageUrl})`
                        : `url(${image})`,
                      backgroundSize: "cover", // Ensures the image covers the container
                      backgroundPosition: "center", // Centers the image to reduce undesirable stretching effects
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 0,
                    }}
                  />
                )}
              </>
            )}
          </Box>

          <Box sx={{ "& > div": { marginBottom: 2 } }}>
            {" "}
            {/* Outer container for overall spacing */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
            >
              <FormControl
                variant="standard"
                sx={{ width: "calc(50% - 16px)" }}
              >
                <InputLabel htmlFor="product-name">Name</InputLabel>
                <BootstrapInput
                  id="product-name"
                  value={productName}
                  onChange={handleNameChange}
                  error={Boolean(nameError)}
                />
                {nameError && (
                  <Typography color="error" variant="caption">
                    {nameError}
                  </Typography>
                )}
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ width: "calc(50% - 16px)" }}
              >
                <InputLabel htmlFor="product-code">Product Code</InputLabel>
                <BootstrapInput
                  id="product-code"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                  error={Boolean(productCodeError)}
                />
                {productCodeError && (
                  <Typography color="error" variant="caption">
                    {productCodeError}
                  </Typography>
                )}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                alignItems: "center", // Ensures vertical alignment at the center
              }}
            >
              <FormControl
                sx={{
                  width: "50%",
                  minHeight: "min-content", // Matching minimum height for uniformity
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <InputLabel id="demo-category-select-label">
                  Category
                </InputLabel>
                <Select
                  labelId="demo-category-select-label"
                  id="demo-category-select"
                  value={category}
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((data, index) => (
                    <MenuItem value={data} key={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "50%",
                  minHeight: "min-content", // Matching minimum height for uniformity
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <InputLabel id="demo-simple-select-label">In Stock</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isInStock ? "Available" : "Not Available"}
                  label="In Stock"
                  onChange={(event) => {
                    setIsInStock(event.target.value === "Available");
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Available">Available</MenuItem>
                  <MenuItem value="Not Available">Not Available</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <CustomizeQuantityAndRatesTable rows={rows} setRows={setRows} />
          {rowError && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {rowError}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              sx={{
                height: "3rem",
                minHeight: "40px",
                // backgroundColor: "#E8BD4A",
              }}
              onClick={() => {
                dispatch(clearSelectedProductForEdition());
                handleClose();
                setImageUrl("");
                setRows([]);
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                height: "3rem",
                minHeight: "40px",
                // backgroundColor: "#E8BD4A",
              }}
              onClick={handleSubmit}
              variant="contained"
              disabled={nameError !== "" || productCodeError !== ""}
            >
              Update
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);
