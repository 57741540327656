import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Items from "../Pages/Items/Items";
import Customize from "../Pages/Customize/Customize";
import SaleItemModal from "./Modal/SaleItemModal";
import CustomizeItemModal from "./Modal/CustomizeItemModal";
import DeleteItemModal from "./Modal/DeleteItemModal";
import AddItemModal from "./Modal/AddItemModal";
import TimeDisplay from "./TimeDisplay/TimeDisplay";
import AddCategory from "./Modal/AddCategory";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { closeAddCategoryModal } from "../app/features/products/productSlice";
import { RootState } from "../app/store";
import { Alert, AlertColor, Snackbar, Stack } from "@mui/material";
import { clearAlert } from "../app/features/alerts/alertSlice";
import BillPrintModal from "./Modal/BillPrintModal";
import {
  closeBillPrintModal,
  openBillPrintModal,
} from "../app/features/cart/cartSlice";
import Settings from "../Pages/Settings/Settings";
import ReceiptDesigner from "../Pages/Receipt-Designer/receipt-designer";
import PrinterStatus from "./PrinterStatus/PrinterStatus";
import Reports from "../Pages/Reports/Reports";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DashboardTab() {
  const role = localStorage.getItem("role");
  const [value, setValue] = React.useState(role === "ADMIN" ? 1 : 0);

  //state
  const isModalOpen = useAppSelector(
    (state) => state.products.isAddCategoryModalOpen
  );
  const isBillModalOpen = useAppSelector(
    (state) => state.cart.isBillPrintModalOpen
  );
  const dispatch = useAppDispatch();
  const { show, message, severity } = useAppSelector(
    (state: RootState) => state.alert
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCustomizeModal, setOpenCustomizeModal] = React.useState(false);
  const handleCustomizeModalOpen = () => setOpenCustomizeModal(true);
  const handleCustomizeModalClose = () => setOpenCustomizeModal(false);

  const [deleteModal, setDeleteModal] = React.useState(false);
  const handleDeleteModalOpen = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);

  const [addItemModal, setAddItemModal] = React.useState(false);
  const handleAddItemModalOpen = () => setAddItemModal(true);
  const handleAddItemModalClose = () => setAddItemModal(false);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (show) {
      timer = setTimeout(() => {
        dispatch(clearAlert());
      }, 3000); // Clear alert after 3 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [show, dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return; // Do not close if the user clicks away
    }
    dispatch(clearAlert());
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiSnackbar-root": {
            top: { xs: "90px", sm: "100px" }, // Adjust the position based on your needs
          },
        }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severity as AlertColor}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <SaleItemModal open={open} handleClose={handleClose} />
        <CustomizeItemModal
          open={openCustomizeModal}
          handleClose={handleCustomizeModalClose}
        />
        <DeleteItemModal
          open={deleteModal}
          handleClose={handleDeleteModalClose}
        />
        <AddItemModal
          handleClose={handleAddItemModalClose}
          open={addItemModal}
        />
        <AddCategory
          open={isModalOpen}
          handleClose={() => dispatch(closeAddCategoryModal())}
        />
        <BillPrintModal
          open={isBillModalOpen}
          handleClose={() => dispatch(closeBillPrintModal())}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#C72128",
              },
            }}
          >
            {role === "ADMIN" && (
              <Tab
                label="Dashboard"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "#C72128" : "inherit",
                  "&.Mui-selected": { color: "#C72128" },
                }}
              />
            )}
            <Tab
              label="Items"
              {...a11yProps(role === "ADMIN" ? 0 : 1)}
              sx={{
                color: value === 1 ? "#C72128" : "inherit",
                "&.Mui-selected": { color: "#C72128" },
              }}
            />
            {role === "ADMIN" && (
              <Tab
                label="Customize"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? "#C72128" : "inherit",
                  "&.Mui-selected": { color: "#C72128" },
                }}
              />
            )}
            <Tab
              label="Settings"
              {...a11yProps(role === "ADMIN" ? 3 : 1)}
              sx={{
                color: value === 3 ? "#C72128" : "inherit",
                "&.Mui-selected": { color: "#C72128" },
              }}
            />
            <Tab
              label="Reports"
              {...a11yProps(role === "ADMIN" ? 4 : 2)}
              sx={{
                color: value === 4 ? "#C72128" : "inherit",
                "&.Mui-selected": { color: "#C72128" },
              }}
            />
             <Tab
              label="Receipt Designer"
              {...a11yProps(4)}
              sx={{
                color: value === 4 ? "#C72128" : "inherit",
                "&.Mui-selected": { color: "#C72128" },
              }}
            /> 
            </Tabs> 
        </Box>
        <Stack flexDirection={"row"} gap={2}>
          <PrinterStatus />
          <TimeDisplay />
        </Stack>
      </Box>
      {role === "ADMIN" && (
        <CustomTabPanel value={value} index={0}>
          <Dashboard />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={role === "ADMIN" ? 1 : 0}>
        <Items handleOpen={handleOpen} />
      </CustomTabPanel>
      {role === "ADMIN" && (
        <CustomTabPanel value={value} index={2}>
          <Customize
            handleOpen={handleCustomizeModalOpen}
            handleDeleteOpen={handleDeleteModalOpen}
            handleAddItem={handleAddItemModalOpen}
          />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={role === "ADMIN" ? 3 : 1}>
        <Settings />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={role === "ADMIN" ? 4 : 2}>
        <Reports />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <ReceiptDesigner />
      </CustomTabPanel> 
    </Box>
  );
}
