import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  Button,
} from "@mui/material";
import {
  FontDownload,
  FormatItalic,
  FormatBold,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
} from "@mui/icons-material";
import { ALIGN, FONT_FAMILY, FONT_SIZE, FONT_STYLE } from "../constants/styles";
import { ModuleProperties, TableRowProp } from "../types";
import TableRow from "./table-row";

interface EditorProps {
  properties: ModuleProperties;
  onUpdateProperties: (
    properties: ModuleProperties,
    shouldUpdate: boolean
  ) => void;
}

const Editor: React.FC<EditorProps> = ({ properties, onUpdateProperties }) => {
  const [tempProperties, setTempProperties] = useState(properties);

  const handleChange = (prop: keyof typeof properties, value: any) => {
    const updatedProperties = { ...tempProperties, [prop]: value };
    setTempProperties(updatedProperties);
  };

  useEffect(() => {
    setTempProperties(properties);
  }, [properties]);

  // Render input fields based on properties
  const renderInputs = () => {
    return Object.entries(tempProperties).map(([key, value]) => {
      switch (key) {
        case "text":
          return (
            <TextField
              key={key}
              label="Text"
              value={value as string}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          );
        case "fontFamily":
          return (
            <Stack key={key}>
              <FormLabel component="legend">Font Family</FormLabel>
              <Select
                value={value as FONT_FAMILY}
                onChange={(e) => handleChange(key, e.target.value)}
              >
                {Object.keys(FONT_FAMILY).map((family) => (
                  <MenuItem key={family} value={(FONT_FAMILY as any)[family]}>
                    {family}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          );
        case "fontStyle":
          return (
            <FormControl component="fieldset" key={key}>
              <FormLabel component="legend">Font Style</FormLabel>
              <RadioGroup
                value={value as FONT_STYLE}
                style={{ flexDirection: "row" }}
                onChange={(e) => handleChange(key, e.target.value)}
              >
                <FormControlLabel
                  value={FONT_STYLE.NORMAL}
                  control={<Radio />}
                  label={<FontDownload />}
                />
                <FormControlLabel
                  value={FONT_STYLE.BOLD}
                  control={<Radio />}
                  label={<FormatBold />}
                />
                <FormControlLabel
                  value={FONT_STYLE.ITALIC}
                  control={<Radio />}
                  label={<FormatItalic />}
                />
                {/* <FormControlLabel value={FONT_STYLE.BOLD_ITALIC} control={<Radio />} label={<><FormatBold /><FormatItalic /></>} /> */}
              </RadioGroup>
            </FormControl>
          );
        case "align":
          return (
            <FormControl component="fieldset" key={key}>
              <FormLabel component="legend">Alignment</FormLabel>
              <RadioGroup
                value={value as ALIGN}
                style={{ flexDirection: "row" }}
                onChange={(e) => handleChange(key, e.target.value)}
              >
                <FormControlLabel
                  value={ALIGN.LEFT}
                  control={<Radio />}
                  label={<FormatAlignLeft />}
                />
                <FormControlLabel
                  value={ALIGN.CENTER}
                  control={<Radio />}
                  label={<FormatAlignCenter />}
                />
                <FormControlLabel
                  value={ALIGN.RIGHT}
                  control={<Radio />}
                  label={<FormatAlignRight />}
                />
              </RadioGroup>
            </FormControl>
          );
        case "size":
          return (
            <Stack key={key}>
              <FormLabel component="legend">Font Size</FormLabel>
              <Select
                value={value as FONT_SIZE}
                onChange={(e) => handleChange(key, e.target.value)}
              >
                {Object.keys(FONT_SIZE).map((fontSize) => (
                  <MenuItem key={fontSize} value={(FONT_SIZE as any)[fontSize]}>
                    {fontSize}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          );
        case "header":
          return (
            <Stack>
              <TableRow
                header={value as TableRowProp[]}
                updateHeader={(val: TableRowProp[]) => handleChange(key, val)}
              />
            </Stack>
          );
        default:
          return null;
      }
    });
  };

  if (!(Object.entries(tempProperties).length > 0)) {
    return <></>;
  } else {
    return (
      <Stack flexDirection={"column"} gap={2}>
        {renderInputs()}
        <Stack flexDirection={"row"} gap={2}>
          <Button
            variant="contained"
            onClick={() => onUpdateProperties(tempProperties, false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onUpdateProperties(tempProperties, true)}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    );
  }
};

export default Editor;
