import { Chip, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { MODULES } from "./modules";
import Editor from "./components/editor";
import { ModuleProperties, ModuleProps } from "./types";

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  flex: 1,
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getModuleListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  flex: 1,
  //width: 150,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  flex: 1,
  //width: 400,
});

const ReceiptDesigner: React.FC = () => {
  const [items, setItems] = useState([...MODULES]);
  const [selected, setSelected] = useState<ModuleProps[]>([
    { ...MODULES[0], id: "0001" },
  ]);
  const [editIndex, setEditIndex] = useState<null | number>(null);
  const [editProperties, setEditProperties] = useState<ModuleProperties>({});

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const list = source.droppableId === "droppable" ? items : selected;
      const reorderedItems = reorder(list, source.index, destination.index);
      source.droppableId === "droppable"
        ? setItems(reorderedItems)
        : setSelected(reorderedItems);
    } else if (source.droppableId === "droppable") {
      const sourceList = [...items];
      const destList = [...selected];
      const draggedItem = [sourceList[source.index]];
      //draggedItem[0].id = String(new Date().getTime());
      destList.splice(destination.index, 0, {
        ...draggedItem[0],
        id: String(new Date().getTime()),
      });
      setSelected([...destList]);
    }
    clearModuleEdit();
  };

  const removeModule = (index: number) => {
    clearModuleEdit();
    selected.splice(index, 1);
    setSelected([...selected]);
  };

  const updateModuleItem = (
    mProperties: ModuleProperties,
    shouldUpdate: boolean
  ) => {
    if (shouldUpdate && editIndex !== null) {
      selected[editIndex].properties = mProperties;
      setSelected([...selected]);
    }
    clearModuleEdit();
  };

  const clearModuleEdit = () => {
    setEditIndex(null);
    setEditProperties({});
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack flexDirection={"row"} gap={10} flex={1}>
        <Stack flex={1}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getModuleListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Stack>
        <Stack flex={2} height={"80vh"} overflow={"scroll"}>
          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {selected?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Stack flexDirection={"row"}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Stack>
                            {item.properties?.text || item.type}
                            <Stack flexDirection={"row"} gap={2}>
                              {item.properties?.header &&
                                item.properties?.header.map((hItem) => (
                                  <Chip
                                    label={hItem.text}
                                    style={{ color: "white" }}
                                  />
                                ))}
                            </Stack>
                          </Stack>
                        </div>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            console.log(index);
                            clearModuleEdit();
                            setEditIndex(index);
                            setEditProperties(selected[index]?.properties);
                          }}
                        >
                          <EditIcon style={{ width: 45, height: 25 }} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeModule(index)}
                          color="primary"
                          size="medium"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Stack>
        <Stack flex={1}>
          <Editor
            properties={editProperties}
            onUpdateProperties={updateModuleItem}
          />
        </Stack>
      </Stack>
    </DragDropContext>
  );
};

export default ReceiptDesigner;
