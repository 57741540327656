import * as React from "react";
import { styled } from "@mui/system";
import { Modal, Box, Button, Typography, Alert } from "@mui/material";
import { clearCart } from "../../app/features/cart/cartSlice";
import { useDispatch } from "react-redux";
import useThermalPrinter from "../../app/hooks/useThermalPrinter";

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  padding: theme.spacing(2, 4, 3),
}));

const BillPrintModal: React.FC<CustomModalProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const {printCurrentBill} = useThermalPrinter();

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContent>
        <Alert variant="filled" severity="success" sx={{ mt: 1 }}>
          Order Successful
        </Alert>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ mt: 4 }}>
          Do you want a print of the bill?
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();
              dispatch(clearCart());
            }}
          >
            No, Thanks
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              printCurrentBill();
              handleClose();
              dispatch(clearCart());
            }}
          >
            Print Receipt
          </Button>
        </Box>
      </ModalContent>
    </StyledModal>
  );
};

export default BillPrintModal;
