import * as React from "react";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import {
  closeAddCategoryModal,
  createCategory,
  fetchCategories,
} from "../../app/features/products/productSlice";
import { setAlert } from "../../app/features/alerts/alertSlice";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function AddCategory({ open }: Props) {
  const [category, setCategory] = React.useState("");
  //state
  const dispatch = useAppDispatch();

  const handleAddCategory = () => {
    if (category.trim()) {
      dispatch(createCategory(category.trim()))
        .unwrap()
        .then(() => {
          dispatch(fetchCategories());
          dispatch(closeAddCategoryModal());
          setCategory("");
          dispatch(
            setAlert({
              show: true,
              message: "Category added successfully!",
              severity: "success",
            })
          );
        })
        .catch((error) => {
          console.error("Failed to create category", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Enter Category Name!",
        severity: "error",
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => dispatch(closeAddCategoryModal())}
        slots={{ backdrop: StyledBackdrop }}
        sx={{
          marginX: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalContent
          sx={{
            width: "500px",
            maxWidth: "500px",
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2, // Adds space between elements
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "1rem", textAlign: "left", fontWeight: "bold" }}
            >
              Enter New Category Item Name
            </Typography>
            <Divider />
          </Box>
          <TextField
            fullWidth
            size="medium" // Larger size for easier touch
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Enter Category Name"
            sx={{ ".MuiInputBase-input": { fontSize: "1rem" } }} // Larger font size for readability
          />
          <Button
            variant="contained"
            size="large" // Larger button for easier touch
            sx={{
              fontSize: "1rem", // Larger text for better visibility
              padding: "10px 20px", // More padding for a larger touch area
              color: "white",
              "&:active": {
                transform: "scale(0.98)", // Slight shrink effect on press for feedback
              },
            }}
            onClick={handleAddCategory}
          >
            Add Category
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

export const ImageContainer = styled("div")`
  width: 100%; // Take full width of the modal content
  height: 200px; // Fixed height for the image container
  overflow: hidden; // Ensure the image does not overflow the container
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6f9; // A light background color for the container
`;
