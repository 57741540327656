import * as React from "react";
import Typography from "@mui/material/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const TimeDisplay = () => {
  const [currentTime, setCurrentTime] = React.useState(
    new Date().toLocaleTimeString()
  );

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Typography
      sx={{
        display: { xs: "none", sm: "flex" },
        alignItems: "center",
        marginRight: 2,
        fontSize: "0.875rem",
        fontFamily: "monospace", // Ensures consistent character width
        width: "120px", // Adjust based on the maximum expected width
      }}
    >
      <AccessTimeIcon sx={{ marginRight: 0.5, fontSize: "1rem" }} />
      {currentTime}
    </Typography>
  );
};

export default TimeDisplay;
