import React from "react";
import Navbar from "../../components/Navbar";
import DashboardTab from "../../components/DashboardTab";
import { Box } from "@mui/material";

const Home = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ mt: 3 }}>
        <DashboardTab />
      </Box>
    </>
  );
};

export default Home;
