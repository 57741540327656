import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import PrinterListComponent from "./components/printer-list";
import { getPrintersList } from "./helper";
import { IPrinterList } from "./ptypes";

const PrinterSettings = () => {
  const [printerList, setPrinterList] = useState<IPrinterList>([]);

  const renderPrinterSettings = async () => {
    const list = await getPrintersList();
    setPrinterList(list);
  };

  useEffect(() => {
    renderPrinterSettings();
  }, []);

  return (
    <Stack gap={2}>
      <Stack flexDirection={"row"} gap={2}>
        <h1> Printer Settings</h1>
        {/* <Button variant="contained">Save Configuration</Button> */}
      </Stack>
      <PrinterListComponent printers={printerList} />
    </Stack>
  );
};

export default PrinterSettings;
