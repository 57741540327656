import { MODULE_KEYS, MODULE_NAMES } from "../constants/modules";
import { ALIGN, FONT_FAMILY, FONT_SIZE, FONT_STYLE } from "../constants/styles";
import { ModuleProps } from "../types";

const MODULES: ModuleProps[] = [
  {
    id: `${MODULE_KEYS.TEXT}-MAIN`,
    name: MODULE_NAMES.TEXT,
    type: MODULE_KEYS.TEXT,
    content: MODULE_NAMES.TEXT,
    properties: {
      text: "",
      fontFamily: FONT_FAMILY.SANS,
      fontStyle: FONT_STYLE.NORMAL,
      align: ALIGN.CENTER,
      size: FONT_SIZE.SMALL,
    },
  },
  {
    id: `${MODULE_KEYS.LINE}-MAIN`,
    name: MODULE_NAMES.LINE,
    type: MODULE_KEYS.LINE,
    content: MODULE_NAMES.LINE,
    properties: {
      fontFamily: FONT_FAMILY.SANS,
      fontStyle: FONT_STYLE.NORMAL,
      align: ALIGN.CENTER,
      size: FONT_SIZE.SMALL,
    },
  },
  {
    id: `${MODULE_KEYS.IMAGE}-MAIN`,
    name: MODULE_NAMES.IMAGE,
    type: MODULE_KEYS.IMAGE,
    content: MODULE_NAMES.IMAGE,
    properties: {
      path: "",
      align: ALIGN.CENTER,
      size: FONT_SIZE.SMALL,
    },
  },
  {
    id: `${MODULE_KEYS.GAP}-MAIN`,
    name: MODULE_NAMES.GAP,
    type: MODULE_KEYS.GAP,
    content: MODULE_NAMES.GAP,
    properties: {},
  },
  {
    id: `${MODULE_KEYS.SPACE_LINE}-MAIN`,
    name: MODULE_NAMES.SPACE_LINE,
    type: MODULE_KEYS.SPACE_LINE,
    content: MODULE_NAMES.SPACE_LINE,
    properties: {},
  },
  {
    id: `${MODULE_KEYS.TABLE}-MAIN`,
    name: MODULE_NAMES.TABLE,
    type: MODULE_KEYS.TABLE,
    content: MODULE_NAMES.TABLE,
    properties: {
      fontFamily: FONT_FAMILY.SANS,
      fontStyle: FONT_STYLE.NORMAL,
      align: ALIGN.CENTER,
      size: FONT_SIZE.SMALL,
      header: [
        { text: "S. No", align: "LEFT", width: "20" },
        { text: "Item", align: "CENTER", width: "40" },
      ],
      values: [],
    },
  },
];

export { MODULES };
