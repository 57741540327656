// src/features/alerts/alertSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertState {
  show: boolean;
  message: string;
  severity: 'success' | 'error';
}

const initialState: AlertState = {
  show: false,
  message: '',
  severity: 'success',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertState>) => {
      const { show, message, severity } = action.payload;
      state.show = show;
      state.message = message;
      state.severity = severity;
    },
    clearAlert: (state) => {
      state.show = false;
      state.message = '';
      state.severity = 'success'; // Reset to default severity if needed
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;

export default alertSlice.reducer;
