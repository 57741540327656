import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Radio,
  FormControlLabel,
  styled,
  Select,
  MenuItem,
} from "@mui/material";
import {
  initialRows,
  metricAbbreviations,
  metricOptions,
} from "../../Constants/Constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ItemsQuantityAndRatesTableProps,
  RowData,
} from "../../Constants/Interfaces";

// The props interface is corrected to match the 'id' field type from RowData
interface EditableTableRowProps {
  id: string; // id should be a string
  mrp: string;
  quantity: string;
  metric: string;
  selectedRowId: string | null;
  onChange: (id: string, field: keyof RowData, value: string) => void; // Updated to expect string for id
  onRadioChange: (id: string) => void;
  onDelete: (id: string) => void;
}

// Corrected the EditableTableRow component
const EditableTableRow: React.FC<EditableTableRowProps> = ({
  id,
  mrp,
  quantity,
  metric,
  selectedRowId,
  onChange,
  onRadioChange,
  onDelete,
}) => {
  return (
    <TableRow key={id}>
      <TableCell>
        <TextField
          size="small"
          value={mrp}
          onChange={(e) => onChange(id, "mrp", e.target.value)}
          // Override styles for the input element
          inputProps={{
            style: { padding: "0.5px 0.5px 0.5px 0.5px !important" },
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          value={quantity}
          onChange={(e) => onChange(id, "quantity", e.target.value)}
          inputProps={{
            style: { padding: "0.5px 0.5px 0.5px 0.5px !important" }, // Removing padding and setting width to auto
          }}
          variant="outlined"
          fullWidth={false}
        />
      </TableCell>
      <TableCell>
        <Select
          size="small"
          value={metric}
          onChange={(e) => onChange(id, "metric", e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) =>
            metricAbbreviations[selected as keyof typeof metricAbbreviations] ||
            selected
          }
          sx={{
            ".MuiSelect-select": {
              fontSize: "1rem", // Smaller font size
              padding: "4px 24px 4px 8px", // Reduced padding, adjust as needed
              height: "30px", // Adjust the height to fit better
            },
            ".MuiSvgIcon-root": {
              // This targets the dropdown icon specifically
              fontSize: "1rem", // Adjust the icon size
            },
          }}
        >
          {metricOptions.map((option) => (
            <MenuItem key={option} value={option} sx={{ fontSize: "0.75rem" }}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <DeleteIcon onClick={() => onDelete(id)} sx={{ cursor: "pointer" }} />
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Radio
              checked={selectedRowId === id}
              onChange={() => onRadioChange(id)}
              size="small"
            />
          }
          label=""
          sx={{
            margin: 0, // Remove the default margin
            "& .MuiFormControlLabel-label": {
              fontSize: "0.75rem", // Reduce label font size
            },
            "& .MuiRadio-root": {
              padding: "2px", // Reduce padding around the radio button for a tighter look
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const ItemsQuantityAndRatesTable: React.FC<ItemsQuantityAndRatesTableProps> = ({
  rows,
  setRows,
}) => {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(
    initialRows[0].id
  );

  const handleChange = (id: string, field: keyof RowData, value: string) => {
    // Check for duplicates when 'quantity' or 'metric' fields are changed
    if (field === "quantity" || field === "metric") {
      const existingRow = rows.find((row) => row.id === id);
      const newName =
        field === "quantity" ? existingRow?.metric : existingRow?.quantity;

      // Check if there's another row with the same 'quantity' and 'metric'
      const isDuplicate = rows.some(
        (row) =>
          row.id !== id &&
          ((field === "quantity" &&
            value === row.quantity &&
            newName === row.metric) ||
            (field === "metric" &&
              value === row.metric &&
              newName === row.quantity))
      );

      if (isDuplicate) {
        alert("Same quantity and quantity type exists. Enter another one.");
        return; // Prevent the state update
      }
    }

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleRadioChange = (id: string) => {
    setSelectedRowId(id);
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, default: "true" };
        }
        const newRow = { ...row };
        delete newRow.default; // This safely deletes the 'default' property
        return newRow;
      });
    });
  };

  const handleAddRow = () => {
    const newRowId = `row_${Date.now()}`; // Example for a unique ID
    const newRow: RowData = { id: newRowId, mrp: "", quantity: "", metric: "" };
    setRows((prevRows) => [...prevRows, newRow]);
    setSelectedRowId(newRowId); // Optionally select new row by default
  };

  // handleDeleteRow filters out the row to be deleted by its ID
  const handleDeleteRow = (id: string) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    if (selectedRowId === id) {
      setSelectedRowId(null); // If the deleted row was selected, clear the selection
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "end", mb: 1 }}>
        <Button variant="outlined" onClick={handleAddRow} color="primary">
          Add Price
        </Button>
      </Box>
      <Table size="small" aria-label="editable table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ padding: "8px", fontSize: "0.875rem" }}
            >
              MRP
            </TableCell>
            <TableCell
              align="center"
              sx={{ padding: "8px", fontSize: "0.875rem" }}
            >
              Qty
            </TableCell>
            <TableCell
              align="center"
              sx={{ padding: "8px", fontSize: "0.875rem" }}
            >
              Metric
            </TableCell>
            <TableCell
              align="center"
              sx={{ padding: "8px", fontSize: "0.875rem" }}
            >
              Delete
            </TableCell>
            <TableCell
              align="center"
              sx={{ padding: "8px", fontSize: "0.875rem" }}
            >
              Default
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <EditableTableRow
              key={row.id}
              id={row.id}
              mrp={row.mrp}
              quantity={row.quantity}
              metric={row.metric}
              selectedRowId={selectedRowId}
              onChange={handleChange}
              onRadioChange={handleRadioChange}
              onDelete={handleDeleteRow}
            />
          ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // This centers the button horizontally
          marginTop: 2,
        }}
      ></Box>
    </Box>
  );
};

export default ItemsQuantityAndRatesTable;
