// src/api/axiosClient.ts
import axios, { AxiosInstance } from 'axios';

const BASE_URL: string = 'https://billdesk.apis.lightworks-services.com';

const axiosClient = (): AxiosInstance => {
  const token = localStorage.getItem("token");

  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export default axiosClient;
