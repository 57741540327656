// src/features/orders/orderSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/axiosClient"; // Ensure this path is correct
import { Order } from "../../../Constants/Interfaces";

// Define initialState
const initialState: {
  orders: Order[],
  status: string, // 'loading' | 'succeeded' | 'failed'
  error: string | null, // string | null | undefined
  currentOrder: Order | null, 
} = {
  orders: [],
  status: "idle", // 'loading' | 'succeeded' | 'failed'
  error: "", // string | null | undefined
  currentOrder: null,
};

// Define the async thunk for fetching orders
export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (
    dateRange: { startTime: number; endTime: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosClient().post(
        "/v1/admin/private/api/get-bills",
        dateRange
      );
      return response.data.data.bills;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Could not fetch orders");
    }
  }
);

// Define the slice
const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setCurrentOrder(state, action) {
      state.currentOrder = action.payload;
    },
    // Define any synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload; // Assumes the payload is the list of orders
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed";
        if (typeof action.payload === "string") {
          state.error = action.payload;
        } else {
          state.error = "An unexpected error occurred";
        }
      });
  },
});

export const { setCurrentOrder } = ordersSlice.actions;

// Export the reducer as default
export default ordersSlice.reducer;
