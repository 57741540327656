import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { TABLEALIGN } from "../constants/styles";
import { TableRowProp } from "../types";

interface TableRowCompProps {
  header: TableRowProp[];
  updateHeader: (val: TableRowProp[]) => void;
}

const TableRow: React.FC<TableRowCompProps> = ({ header, updateHeader }) => {
  //const [inputData, setInputData] = useState<TableRowProp[]>(header);

  const handleAddRow = () => {
    updateHeader([...header, { text: "", align: "LEFT", width: "" }]);
  };

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const updatedData = [...header];
    updatedData[index][name as keyof TableRowProp] = value;
    updateHeader(updatedData);
  };

  const handleRemoveRow = (index: number) => {
    const updatedData = [...header];
    updatedData.splice(index, 1);
    updateHeader(updatedData);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography>Text</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Aligment</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Width%</Typography>
        </Grid>
      </Grid>
      {header?.map((row, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xs={3}>
            <TextField
              type="text"
              name="text"
              value={row.text}
              onChange={(e) => handleInputChange(index, e)}
              placeholder="Text"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            {/* <TextField
              type="text"
              name="align"
              value={row.align}
              onChange={(e) => handleInputChange(index, e)}
              placeholder="Alignment"
              fullWidth
            /> */}

            <Select
              value={row.align as TABLEALIGN}
              onChange={(e) =>
                handleInputChange(index, {
                  target: { name: "align", value: e.target.value },
                } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
              }
            >
              {Object.keys(TABLEALIGN).map((family) => (
                <MenuItem key={family} value={(TABLEALIGN as any)[family]}>
                  {family}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              name="width"
              value={row.width}
              onChange={(e) => handleInputChange(index, e)}
              placeholder="Width"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <IconButton
              aria-label="delete"
              onClick={() => handleRemoveRow(index)}
              color="primary"
              size="medium"
            >
              <DeleteIcon style={{ width: 45, height: 25 }} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" onClick={handleAddRow}>
        Add Row
      </Button>
    </div>
  );
};

export default TableRow;
