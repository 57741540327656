import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ClientInfoType,
  ISettingsState,
  PrinterSettings,
} from "../../../Constants/Interfaces";
import axiosClient from "../../../api/axiosClient";
import { establishConnect } from "../../../Pages/Settings/Printer/helper";

const initialState: ISettingsState = {
  clientInfo: null,
  status: "idle",
  error: null,
  // @ts-ignore
  myPrinter: window.electron ? window.electron.printerOptions : {},
  loaders: {
    saveLoader: false,
    connectionLoader: false
  },
  selectedPrinter: {
    name: "POS80 Printer",
    productId: "12345",
    vendorId: "67890",
    connectionType: "USB",
    driverInfo: "Example Driver",
    printerLocation: "Office",
    isPrinterConfigured: false,
    connectionSettings: {
      productId: "12345",
      vendorId: "67890",
      remoteHostIp: "192.168.1.100",
      remoteHostPort: "8080",
    },
  },
};

export const fetchClientInfo = createAsyncThunk<
  ClientInfoType,
  void,
  { rejectValue: string }
>("settings/fetchClientInfo", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosClient().get<{ data: ClientInfoType }>(
      `/v1/admin/private/api/get-client-info/66342517d8e1512a23bb9404`
    );
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const updatePrinterSettings = createAsyncThunk<
  void,
  PrinterSettings,
  { rejectValue: string }
>(
  "settings/updatePrinterSettings",
  async (selectedPrinter, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(
        `/v1/admin/private/api/update-printer-settings/66342517d8e1512a23bb9404`,
        {
          printerSettings: selectedPrinter,
        }
      );
      if (!response.data.isSuccess) {
        throw new Error("Failed to save printer configuration ");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPrinterInfo(state, action) {
      state.selectedPrinter = { ...state.selectedPrinter, ...action.payload };
    },
    setConnectionInfo(state, action) {
      if(action.payload.productId && action.payload.vendorId) {
        state.selectedPrinter.productId = action.payload.productId;
        state.selectedPrinter.vendorId = action.payload.vendorId;
      }
      state.selectedPrinter.connectionSettings = {
        ...state.selectedPrinter.connectionSettings,
        ...action.payload,
      };
      state.selectedPrinter.isPrinterConfigured = false;
    },
    setIsPrinterConnected(state, action) {
      state.selectedPrinter.isPrinterConfigured = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientInfo.pending, (state) => {
        state.status = "loading";
        state.loaders.connectionLoader = true;
      })
      .addCase(fetchClientInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clientInfo = action.payload;
        state.selectedPrinter = action.payload.printerSettings;
        // if (state.selectedPrinter.productId && state.selectedPrinter.vendorId) {
        //   establishConnect(
        //     state.selectedPrinter.vendorId,
        //     state.selectedPrinter.productId
        //   ).then((val:boolean) => {
        //     state.selectedPrinter.isPrinterConfigured = val || false;
        //   })
        // }
        state.loaders.connectionLoader = false;
      })
      .addCase(fetchClientInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Something went wrong";
        state.loaders.connectionLoader = false;
      })
      .addCase(updatePrinterSettings.pending, (state) => {
        state.loaders.saveLoader = true;
      })
      .addCase(updatePrinterSettings.fulfilled, (state) => {
        state.loaders.saveLoader = false;
      })
      .addCase(updatePrinterSettings.rejected, (state) => {
        state.loaders.saveLoader = false;
      });
  },
});

export const { setPrinterInfo, setConnectionInfo, setIsPrinterConnected } = settingsSlice.actions;

export default settingsSlice.reducer;
