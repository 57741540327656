import { createBrowserRouter, createHashRouter } from "react-router-dom";
import Login from "../Pages/Login";
import Home from "../Pages/Home/Home";
import { login } from "../components/utils/authService";
import { PrivateRoute } from "./PrivateRoute";

const onSubmit = async (id: string, password: string) => {
  try {
    const result = await login(id, password);
    if (result.isSuccess) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("role", result.data.role);
    } else {
      throw new Error("Login failed: " + result.message);
    }
  } catch (error) {
    throw error;
  }
};

const LoginWrapper = () => {
  return <Login onSubmit={onSubmit} />;
};

const router = createHashRouter([
  { path: "/", element: <LoginWrapper /> },
  {
    path: "/home",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
]);

export default router;
