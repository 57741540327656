import Receipt from "@mui/icons-material/Receipt";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";

import ConnectedTv from "@mui/icons-material/ConnectedTv";

import usePrinter from "../../../../app/hooks/usePrinterConfiguration";

const PrinterConnection: React.FC = () => {
  const loaders = useAppSelector((state) => state.settings.loaders);
  const selectedPrinter = useAppSelector(
    (state) => state.settings.selectedPrinter
  );
  const [connectionType, setConnectionType] = useState<string>("USB");
  const [remoteHostIp, setRemoteHostIp] = useState<string>("192.168.1.75");
  const [remotePort, setRemotePort] = useState<number>(1600);
  const [vendorId, setVendorId] = useState<string>("");
  const [productId, setProductId] = useState<string>("");

  const { saveConnection, establishPrinterConnection, testPrinting } =
    usePrinter();

  const handleChangeConnectionType = (event: ChangeEvent<HTMLInputElement>) => {
    setConnectionType(event.target.value);
  };

  const handleChangeRemoteHostIp = (event: ChangeEvent<HTMLInputElement>) => {
    setRemoteHostIp(event.target.value);
  };

  const handleChangeRemotePort = (event: ChangeEvent<HTMLInputElement>) => {
    setRemotePort(Number(event.target.value));
  };

  const handleChangeVendorId = (event: ChangeEvent<HTMLInputElement>) => {
    setVendorId(event.target.value);
  };

  const handleChangeProductId = (event: ChangeEvent<HTMLInputElement>) => {
    setProductId(event.target.value);
  };

  // const saveConnection = () => {
  //   try {
  //     dispatcher(setConnectionInfo({ productId, vendorId }));
  //     if (selectedPrinter) {
  //       dispatcher(
  //         updatePrinterSettings({ ...selectedPrinter, productId, vendorId })
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    setVendorId(selectedPrinter.connectionSettings.vendorId);
    setProductId(selectedPrinter.connectionSettings.productId);
  }, [selectedPrinter]);

  // const establishPrinterConnection = () => {
  //   try {
  //     const isPrinterConnect = establishConnect(vendorId, productId);
  //     dispatcher(setIsPrinterConnected(isPrinterConnect));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <Stack flexDirection={"column"} gap={2}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Connection Type</FormLabel>
        <RadioGroup
          row
          aria-label="connectionType"
          name="connectionType"
          value={connectionType}
          onChange={handleChangeConnectionType}
        >
          <FormControlLabel value="USB" control={<Radio />} label="USB" />
          <FormControlLabel
            value="TCP"
            control={<Radio />}
            label="TCP"
            disabled
          />
        </RadioGroup>
      </FormControl>
      {connectionType === "USB" && (
        <>
          <TextField
            label="Vendor ID"
            variant="outlined"
            value={vendorId}
            onChange={handleChangeVendorId}
          />
          <TextField
            label="Product ID"
            variant="outlined"
            value={productId}
            onChange={handleChangeProductId}
          />
        </>
      )}
      {connectionType === "TCP" && (
        <>
          <TextField
            label="Remote Host IP"
            variant="outlined"
            value={remoteHostIp}
            onChange={handleChangeRemoteHostIp}
          />
          <TextField
            label="Remote Port"
            variant="outlined"
            value={remotePort}
            onChange={handleChangeRemotePort}
          />
        </>
      )}
      <Stack flexDirection={"row"} gap={2}>
        <LoadingButton
          loading={loaders.connectionLoader}
          loadingPosition="start"
          startIcon={<ConnectedTv />}
          variant="contained"
          onClick={() => establishPrinterConnection(vendorId, productId)}
        >
          Establish Connection
        </LoadingButton>
        <LoadingButton
          loading={loaders.saveLoader}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={() => saveConnection(vendorId, productId)}
        >
          Save Connection
        </LoadingButton>

        <LoadingButton
          loading={loaders.saveLoader}
          loadingPosition="start"
          startIcon={<Receipt />}
          variant="contained"
          onClick={testPrinting}
        >
          Test Print
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default PrinterConnection;
